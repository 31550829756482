<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'home' }" class="root"> Dashboard </router-link>
      /
      <router-link :to="{ name: 'add-book' }" class="root">
        Add Book
      </router-link>
      <span class="child">/ Book Item </span>
    </v-row>
    <br />
    <v-card class="card">
      <v-card-title class="heading justify-center">Book Item</v-card-title>
      <v-card-text>
        <div class="bookdata">
          <div class="bookInfo">
            <!-- <v-row> -->
            <v-col sm="6" class="value" lg="1">ISBN</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.isbn }}</v-col>
            <v-col sm="6" class="value" lg="1">Title</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.title }}</v-col>
            <v-col sm="6" class="value" lg="1">Copies</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.copies }}</v-col>
          </div>
          <!-- </v-row> -->
          <div class="d-flex">
            <v-col sm="6" class="value" lg="1">Edition</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.edition }}</v-col>
            <v-col sm="6" class="value" lg="1">Volume</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.volume }}</v-col>
            <v-col sm="6" class="value" lg="1">Book Format</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.bookFormat }}</v-col>
          </div>
          <div class="d-flex">
            <v-col sm="6" class="value" lg="1">Book Type</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.bookType }}</v-col>
            <v-col sm="6" class="value" lg="1">Category</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.category }}</v-col>
            <v-col sm="6" class="value" lg="1">Publisher</v-col>
            <v-col sm="6" lg="3" class="data">: {{ bookData.publisher }}</v-col>
          </div>
          <div class="d-flex">
            <v-col sm="6" class="value" lg="1">Author(s)</v-col>
            <v-col sm="6" lg="11" class="data">: {{ bookData.authorsname }}</v-col>
          </div>
        </div>
        <v-data-table disable-pagination=true :headers="headers" :items="bookItemlist" class="elevation-1 nowrap"
          :search="search">
          <template v-slot:item.acc_no="props">
            <v-edit-dialog large transition save-text="Upload" persistent style="width:100%;"
              @save="savephoto(props.item)" @cancel="cancelphoto" @open="openphoto" @close="closephoto">
              <v-icon class="green--text">mdi-upload</v-icon>

              <template v-slot:input>
                <div class="mt-4 text-h6">
                  Choose Photo
                </div>
                <v-file-input class="ma-5" label="Select File" single-line counter v-model="file"></v-file-input>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details>
              </v-text-field>
              <span style="width: 20px"></span>
              <!-- <v-btn class="mb-2 button" >Export</v-btn> -->
              <download-excel class="mar" :data="bookItemlist" :fields="export_table_headers" worksheet="bookItemlist"
                name="BookItem.xls">
                <v-btn color="success" dark class="mb-2">
                  Export<v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-form v-model="valid" ref="form">
                    <v-card-title class="heading">
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row justify="center" class="mt-2 ml-2">
                          <v-text-field disabled dense outlined filled v-model='editedItem.access_no'
                            label="Accession No"></v-text-field>
                          <v-icon @click="openEditAcesstion()" class="mx-3 mb-6">mdi-pencil</v-icon>
                        </v-row>
                        <v-dialog width="600" v-model="accessioneditdialog">
                          <v-card>
                            <v-card-title>
                              Edit Accession No
                            </v-card-title>
                            <v-card-text>
                              <v-row align="center" justify="center">
                                <v-col cols="12">
                                  <label>Enter New Aceesion No</label>
                                  <v-text-field dense outlined v-model="selecttedacesstion_no"
                                    persistent-hint></v-text-field>

                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-row justify="end" class="pa-2 m-2">
                                <v-btn class="mr-5" @click="accessioneditdialog = false"> Cancel</v-btn>
                                <v-btn class="primary" @click="accession_no_update();"> update</v-btn>

                              </v-row>

                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-row justify="" class="ml-2 mb-5 ">
                          <span
                            style="background-color:#F0F0F0;border-radius: 5px; width:88% ;border: 1px solid #B2B2B2; ;"
                            class=" pa-2 py-3 grey--text">{{ bookData.isbn }}:{{ bookData.title }}</span>
                          <v-icon @click="titleeditdialog = true" class="ml-3">mdi-pencil</v-icon>
                        </v-row>
                        <v-dialog width="600" v-model="titleeditdialog">
                          <v-card>
                            <v-card-title>
                              Edit Book
                            </v-card-title>
                            <v-card-text>
                              <v-row align="center" justify="center">
                                <v-col cols="12">
                                  <label>Select New Book</label>

                                  <v-combobox style="padding: 0px; margin: 0px" v-model="selecttedbook"
                                    :items="allBookInfo" item-text="displayname" item-value="id" outlined hide-no-data
                                    hide-details dense @input.native="searchtitle($event.srcElement.value)"></v-combobox>
                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-row justify="end" class="pa-2 m-2">
                                <v-btn class="mr-5" @click="titleeditdialog = false"> Cancel</v-btn>
                                <v-btn class="primary" @click="bookupdate();"> update</v-btn>

                              </v-row>

                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-row justify="center" class="my-5 ml-2">
                          <v-text-field disabled dense outlined filled v-model='editedItem.barcode_no'
                            label="Barcode"></v-text-field>
                          <v-icon @click="openEditbarcode()" class="mx-3 mb-6">mdi-pencil</v-icon>
                        </v-row>
                        <v-dialog width="600" v-model="barcodeeditdialog">
                          <v-card>
                            <v-card-title>
                              Edit Barcode
                            </v-card-title>
                            <v-card-text>
                              <v-row align="center" justify="center">
                                <v-col cols="12">


                                  <label>Enter New Barcode</label>
                                  <v-text-field dense outlined v-model="selecttedbarcode" persistent-hint></v-text-field>


                                </v-col>
                              </v-row>
                            </v-card-text>
                            <v-card-actions>
                              <v-row justify="end" class="pa-2 m-2">
                                <v-btn class="mr-5" @click="barcodeeditdialog = false"> Cancel</v-btn>
                                <v-btn class="primary" @click="barcodeupdate();"> update</v-btn>

                              </v-row>

                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-row justify="center" class="ma-5">
                          <router-link :to="{
                            name: 'issue-book',
                            params: { assno: editedItem.access_no, id: editedItem.id },
                          }" class="root">
                            <v-btn dark class="primary"> ISSUE BOOK</v-btn>
                          </router-link>

                          <router-link :to="{ name: 'reserve-book', params: { assno: bookData.isbn, id: editedItem.id } }"
                            class="root">
                            <v-btn dark class="primary"> RESERVE BOOK</v-btn>
                          </router-link>


                          <!-- <v-btn dark class="primary" @click="pushSingleBookToReserve(editedItem)"> RESERVE BOOK</v-btn> -->

                        </v-row>
                        <v-col cols="12" class="fields">
                          <label>Rack No</label>
                          <v-autocomplete dense outlined @change="getRackBlockNo()" :items="racklist" item-value="rackId"
                            item-text="rackNo" v-model="editedItem.rackId" persistent-hint></v-autocomplete>
                        </v-col>
                        <p style="color: red" v-if="!isRackAvailable">
                          Selected Rack and Block not available!!!
                        </p>
                        <v-col cols="12" class="fields">
                          <label>Block No</label>
                          <v-autocomplete dense outlined :items="blockList" @change="checkRackAvailability"
                            item-value="block_no" item-text="block_no" v-model="editedItem.block_no"
                            persistent-hint></v-autocomplete>
                        </v-col>

                        <v-col cols="12" class="fields">
                          <label>Withdrawal No</label>
                          <v-text-field dense outlined v-model="editedItem.w_no" item-text="roleLink_list_id"
                            item-value="id" persistent-hint></v-text-field>
                        </v-col>

                        <v-col cols="12" class="fields">
                          <label>Withdrawal Date</label>
                          <v-menu v-model="showPicker5" :close-on-content-click="false" transition="scale-transition"
                            offset-y full-width max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field v-model="editedItem.w_date" placeholder="Select Date" hint="YYYY/MM/DD"
                                persistent-hint dense readonly outlined v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedItem.w_date" no-title
                              @input="showPicker1 = false"></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" class="fields">
                          <label>Publication Place</label>
                          <v-text-field dense outlined v-model="editedItem.p_place" item-text="roleLink_list_id"
                            item-value="id" persistent-hint></v-text-field>
                        </v-col>
                        <v-col cols="12" class="fields">
                          <v-checkbox v-model="editedItem.isDonated" label="Is Received From Donation?" color="black"
                            dense hide-details></v-checkbox>
                        </v-col>
                         <v-col cols="12" class="fields" v-if="editedItem.isDonated">
                          <br>
                          <label>Donor Name </label>
                          <v-text-field v-model="editedItem.book_donar_name" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" class="fields" v-if="!editedItem.isDonated">
                          <br>
                          <label>Bill </label>
                          <v-icon color="blue" class="mr-2" @click="bill_dialog = true">mdi-plus-box</v-icon>
                          <v-autocomplete :items="billlist" item-text="bill" item-value="id" v-model="editedItem.billid"
                            persistent-hint @input.native="billsearch('text', $event.srcElement.value)"></v-autocomplete>
                        </v-col>
                        <v-dialog v-model="bill_dialog" width="500">
                          <v-card>
                            <v-card-title class="grey lighten-2">Add Bill</v-card-title>
                            <v-card-text style="padding-bottom: 0px">
                              <label class="add-text">Bill Number</label><span
                                style="color: red; font-weight: bolder">*</span>
                              <v-text-field dense outlined v-model="billnumber" item-text="roleLink_list_id"
                                item-value="id" persistent-hint></v-text-field>
                              <label>Source/vendor</label>
                              <v-icon color="blue" class="mr-2" @click="vendor_dialog = true">mdi-plus-box</v-icon>
                              <v-autocomplete :items="vendorlist" item-text="vendor" item-value="vendorid"
                                v-model="vendor" persistent-hint
                                @input.native="searchVendorDropdown($event.srcElement.value)"></v-autocomplete>
                              <label>Date of Purchase</label>
                              <v-menu v-model="showPickerPurchase" :close-on-content-click="false"
                                transition="scale-transition" offset-y full-width max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                  <v-text-field v-model="purchasedate" placeholder="Select Date" hint="YYYY/MM/DD"
                                    persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="purchasedate" no-title :min="nowDate"
                                  :max="getEndDate"></v-date-picker>
                              </v-menu>
                              <label>Book Purchase price</label>
                              <v-text-field dense outlined v-model="price" item-text="roleLink_list_id" item-value="id"
                                persistent-hint type="number" @input="calbill_discount()"></v-text-field>
                              <label>Book Discount</label>
                              <v-text-field dense outlined v-model="discount" item-text="roleLink_list_id" item-value="id"
                                persistent-hint type="number" @input="calbill_discount()"></v-text-field>
                              <label>Book price</label>
                              <v-text-field dense outlined v-model="bookprice" item-text="roleLink_list_id"
                                item-value="id" persistent-hint type="number"></v-text-field>
                              <label class="add-text">PO Number</label>
                              <v-text-field dense outlined v-model="ponumber" item-text="roleLink_list_id" item-value="id"
                                persistent-hint></v-text-field>
                              <label>PO Date</label>
                              <v-menu v-model="showPickerPO" :close-on-content-click="false" transition="scale-transition"
                                offset-y full-width max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                  <v-text-field v-model="podate" placeholder="Select Date" hint="YYYY/MM/DD"
                                    persistent-hint dense readonly outlined v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="podate" no-title :min="nowDate" :max="getEndDate"></v-date-picker>
                              </v-menu>
                            </v-card-text>
                            <div class="text-center">
                              <v-spacer></v-spacer>
                              <v-btn class="m-3" color="error" @click="bill_dialog = false">Close</v-btn>
                              <v-btn class="m-3" color="success" @click="addBill()">Add</v-btn>
                            </div>
                          </v-card>
                        </v-dialog>

                        <v-dialog v-model="vendor_dialog" width="500">
                          <v-card>
                            <v-card-title class="grey lighten-2">Add Vendor</v-card-title>
                            <v-card-text style="padding-bottom: 0px">
                              <label class="add-text">Vendor Name</label>
                              <span style="color: red; font-weight: bolder">*</span>
                              <v-text-field class="text" v-model="vendorname" outlined dense clearable
                                required></v-text-field>
                            </v-card-text>
                            <div class="text-center">
                              <v-spacer></v-spacer>
                              <v-btn class="m-3" color="error" @click="vendor_dialog = false">Close</v-btn>
                              <v-btn class="m-3" color="success" @click="addVendor()">Add</v-btn>
                            </div>
                          </v-card>
                        </v-dialog>

                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                      <v-btn class="button" @click="save" :disabled="!valid || !isRackAvailable">Save</v-btn>
                    </v-card-actions>
                    <!-- :disabled="!valid" -->
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon>
            </div>
          </template>
          <template v-slot:item.bookItem="{ item }">
            <router-link :to="{ name: 'book-item', params: { bookId: item.id } }" style="text-decoration: none">
              <v-btn small color="info">Book Item</v-btn>
            </router-link>
          </template>
          <template v-slot:item.borrowed_date="{ item }">
            <p class="m-0" v-if="item.book_status !== 'Issued'">-</p>
            <p class="m-0" v-else>{{ item.borrowed_date }}</p>
          </template>
          <template v-slot:item.due_date="{ item }">
            <p class="m-0" v-if="item.book_status !== 'Issued'">-</p>
            <p class="m-0" v-else>{{ item.due_date }}</p>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    nowDate: '0000-01-01',
    vendor_dialog: false,
    vendorname: "",
    bill_dialog: false,
    billlist: [],
    billnumber: "",
    purchasedate: "",
    ponumber: "",
    podate: "",
    vendorlist: [],
    price: 0,
    discount: 0,
    bookprice: 0,
    vendor: "",
    barcodeeditdialog: false,
    selecttedbarcode: "",
    showPickerPurchase: false,
    showPickerPO: false,
    showPicker1: false,
    accessioneditdialog: false,
    selecttedacesstion_no: "",
    titleeditdialog: false,
    selecttedbook: "",
    allBookInfo: [],
    file: null,
    export_table_headers: {
      "Sr.no.": "srno",
      "Accession No": "access_no",
      "Book Status": "book_status",
      "Rack No": "rackNo",
      "Block No": "block_no",
    },
    showPicker5: false,
    bookData: null,
    bookItemlist: [],
    racklist: [],
    bookStatus: [],
    valid: false,
    overlay: false,
    errorMsg: {
      roleLinkId: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    showPicker: false,
    showPicker1: false,
    selectedDate: null,
    menu1: false,
    menu2: false,
    org: "",
    inSave: true,
    isRackAvailable: true,
    dialog: false,
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      { text: "Actions", value: "action", sortable: false },
      {
        text: "Upload Photo",
        align: "center",
        sortable: true,
        value: "acc_no",
      },

      {
        text: "Accession Number",
        align: "left",
        sortable: true,
        value: "access_no",
      },
      {
        text: "Date Of Entry",
        align: "left",
        sortable: true,
        value: "periodic_entry_date",
      },
      {
        text: "Department",
        align: "left",
        sortable: true,
        value: "department",
      },
      {
        text: "Bill No",
        align: "left",
        sortable: true,
        value: "billNo",
      },
      {
        text: "Purchase Date",
        align: "left",
        sortable: true,
        value: "purchaseDate",
      },
      {
        text: "Barcode",
        align: "left",
        sortable: true,
        value: "barcode_no",
      },
      {
        text: "Book Status",
        align: "left",
        sortable: true,
        value: "book_status",
      },

      {
        text: "Member",
        align: "left",
        sortable: true,
        value: "member",
      },
      // {
      //     text: "Borrowed Date",
      //     align: "left",
      //     sortable: true,
      //     value: "borrowed_date",
      // },
      {
        text: "Issue Date",
        align: "left",
        sortable: true,
        value: "borrowed_date",
      },
      // {
      //     text: "Due Date",
      //     align: "left",
      //     sortable: true,
      //     value: "due_date",
      // },
      {
        text: "Due Date",
        align: "left",
        sortable: true,
        value: "due_date",
      },
      {
        text: "Rack No",
        align: "left",
        sortable: true,
        value: "rackNo",
      },
      {
        text: "Block No",
        align: "left",
        sortable: true,
        value: "block_no",
      },
      {
        text: "Withdrawal No",
        align: "left",
        sortable: true,
        value: "w_no",
      },
      {
        text: "Withdrawal Date",
        align: "left",
        sortable: true,
        value: "w_date",
      },
      {
        text: "Publication Place",
        align: "left",
        sortable: true,
        value: "p_place",
      },
      //  { text: "Book Item(s)", value: "bookItem", sortable: false },
    ],
    bookCategoryList: [],
    roleLink_list: [],
    blockList: [],
    role_list: [],
    editedIndex: -1,
    editedItem: {
      w_date: "",
      w_no: "",
      p_place: "",
      isactive: false,
      id: "",
      access_no: null,
      barcode_no: null,
      barcode: null,
      book_status: null,
      borrowed_date: null,
      due_date: null,
      rackNo: null,
      block_no: null,
      book_statusId: null,
      bookStatus: null,
      member: null,
      memnerId: null,
      rackId: null,
    },
    defaultItem: {
      isactive: false,
      id: "",
      access_no: null,
      barcode: null,
      book_status: null,
      borrowed_date: null,
      due_date: null,
      rackNo: null,
      block_no: null,
      book_statusId: null,
      bookStatus: null,
      member: null,
      memnerId: null,
      rackId: null,
    },
    bookId: null,
  }),

  computed: {
    getEndDate() {
      var endDate = new Date();
      return endDate.toISOString().slice(0, 10)
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Book Item" : "Edit Book Item";
    },
  },

  // watch: {
  //     dialog(val) {
  //         val || this.close();
  //     },
  //     overlay(val) {
  //         val = () => {
  //             this.overlay = false;
  //         };
  //     },
  // },
  mounted() {
    console.log("this.$route.query.bookId " + this.$route.query.bookId);
    console.log("this.$route.params.bookId " + this.$route.params.bookId);

    this.bookId = this.$route.params.bookId;
    console.log("this.bookId " + this.bookId);
    if (!this.bookId || this.bookId == "undefined")
      this.bookId = this.$route.query.bookId;
    this.onLoad();
  },
  methods: {
    openEditAcesstion() {
      this.accessioneditdialog = true;
      this.selecttedacesstion_no = this.editedItem.access_no;
    },
    openEditbarcode() {
      this.barcodeeditdialog = true;
      this.selecttedbarcode = this.editedItem.barcode_no;
    },
    accession_no_update() {
      if (confirm("Are You Sure ?")) {
        if (this.selecttedacesstion_no != "") {
          var params = {
            old: this.editedItem.access_no,
            new: this.selecttedacesstion_no,
          };
          axios.post("/IssueBook/editaceestionno", params).then((res) => {
            console.log(res);
            if (res.data.data.msg == "200") {
              this.showSnackbar(
                "green",
                "Accession Number Changed from " +
                this.editedItem.access_no +
                " To " +
                this.selecttedacesstion_no
              );
              this.accessioneditdialog = false;
              this.dialog = false;
              this.onLoad();
            } else {
              this.showSnackbar("red", res.data.data.msg);
            }
          });
        }
      }
    },

    barcodeupdate() {
      if (confirm("Are You Sure ?")) {
        if (this.selecttedbarcode != "") {
          var params = {
            accno: this.editedItem.access_no,
            barcode: this.selecttedbarcode,
          };
          axios.post("/IssueBook/editbarcodeno", params).then((res) => {
            console.log(res);
            if (res.data.data.msg == "200") {
              this.showSnackbar("green", "Barcode updated Successfully.");
              this.barcodeeditdialog = false;
              this.dialog = false;
              this.onLoad();
            } else {
              this.showSnackbar("red", res.data.data.msg);
            }
          });
        }
      }
    },

    bookupdate() {
      if (confirm("Are You Sure ?")) {
        if (this.selecttedbook != "") {
          var params = {
            srcbook: this.editedItem.access_no,
            destbook: this.selecttedbook.id,
          };
          axios.post("/IssueBook/movebookitem", params).then((res) => {
            console.log(res);
            if (res.data.data.msg == "200") {
              this.titleeditdialog = false;
              this.dialog = false;
              this.onLoad();
            } else {
              this.showSnackbar("red", res.data.data.msg);
            }
          });
        }
      }
    },
    searchtitle(value) {
      if (value != "") {
        var params = { text: value, seaechbyacc: "isbn:title" };
        axios.post("/IssueBook/getbooksinfo1", params).then((res) => {
          if (res.data.msg == "200") {
            this.allBookInfo = res.data.allBookInfo;
          }
        });
      }
    },
    savephoto(value) {
      console.log(value.access_no);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("accession_number", value.access_no);
      axios.post("/Librarian/uploadbookphoto", formData).then((res) => {
        if (res.data.msg == "200") {
          this.showSnackbar("green", "Successfully Uploaded Photo.");
          this.query_list = res.data.query_list;
          this.clearData();
          this.loader = false;
        } else {
          this.showSnackbar("red", res.data.msg);
          this.loader = false;
        }
      });
    },
    cancelphoto() {
      // this.showSnackbar("green", "cancelphoto");
    },
    openphoto() {
      // this.showSnackbar("green", "openphoto");
    },
    closephoto() {
      //  this.showSnackbar("green", "closephoto");
    },

    pushSingleBookToReserve(bookItem) {
      this.$router.push({
        path: "/reserve-book",
        params: { singleBook: bookItem.access_no },
      });
    },
    editItem(item) {
      console.log("this.editedItem", this.editedItem);
      // item.borrowed_date = moment(item.borrowed_date).format('YYYY-MM-DD')
      this.blockList.push({ block_no: item.block_no });
      console.log("item---", item);

      this.inSave = false;
      this.editedIndex = this.bookItemlist.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.billsearch('id', this.editedItem.billid);
      this.dialog = true;
      var name = item.role;
      for (let x in this.role_list) {
        // alert(this.role_list[x].name+" : "+name)
        if (this.role_list[x].name == name) {
          this.editedItem.role = this.role_list[x].name;
          this.editedItem.roleId = this.role_list[x].id;
          // this.item.role = this.role_list[x].name
          break;
        }
      }
    },
    onLoad() {
      var temp;
      if (this.bookId != null) {
        localStorage.setItem("bookId", this.bookId);
        temp = this.bookId;
      } else {
        temp = localStorage.getItem("bookId");
      }
      let data = {
        bookId: temp,
      };
      this.overlay = true;
      axios
        .post("/Librarian/getBookItemData", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.bookData = res.data.bookData;
            this.bookItemlist = res.data.bookItemlist;
            this.racklist = res.data.racklist;
            this.bookStatus = res.data.bookStatus;

            this.bookList = res.data.book_list;
            this.bookTypeList = res.data.bookTypeList;
            this.bookFormatList = res.data.bookFormatList;
            this.bookCatList = res.data.bookCatList;
            this.languageList = res.data.languageList;
            this.publisherList = res.data.publisherList;
            this.libraryList = res.data.lib_list;
            this.authorList = res.data.authorList;
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // ==this.$refs.form.reset()
      // setTimeout(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem);
      //     this.editedIndex = -1;
      // }, 300);
    },

    save() {
      this.editedItem.due_date = moment(
        String(this.editedItem.due_date)
      ).format("DD-MM-YYYY");
      this.editedItem.borrowed_date = moment(
        String(this.editedItem.borrowed_date)
      ).format("DD-MM-YYYY");

      if (this.editedIndex > -1) {
        if (this.$refs.form.validate()) {
          axios
            .post("/Librarian/editBookItem", this.editedItem)
            .then((res) => {
              if (res.data.msg == "success") {
                this.showSnackbar("#4caf50", "Book Updated Successfully...");
                this.onLoad();
              } else if (res.data.status == "201") {
                this.showSnackbar("#b71c1c", "Book Not Found");
              }
            }).catch((error) => {
              window.console.log(error);
            });
          this.close();
        }
      }
    }, //save()

    getRackBlockNo() {
      console.log(this.editedItem);
      const data = {
        rackId: this.editedItem.rackId,
        block_no: this.editedItem.block_no,
      };
      console.log("data------", data);
      axios
        .post("/Librarian/getRackBlockNo", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.blockList = res.data.blockList;
            this.isRackAvailable = res.data.isRackAvailable;
          } else if (res.data.status.code == "NA") {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    checkRackAvailability() {
      const data = {
        rackId: this.editedItem.rackId,
        block_no: this.editedItem.block_no,
      };
      console.log("data----", data);
      axios
        .post("/Librarian/checkRackAvailability", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.isRackAvailable = res.data.isRackAvailable;
          } else if (res.data.status.code == "NA") {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    activeOn(item) {
      axios
        .post("/admin/rackIsactive", item)
        .then(() => {
          // this.sec_mode_list = res.data.user_list;
          this.showSnackbar("#4CAF50", "Rack updated successfully...");
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong!!!");
          console.log(error);
        })
        .finally(() => {
          this.edit_btn_load = false;
          axios;
        });
    },

    isValidated() {
      if (this.editedItem.roleLinkId) {
        return true;
      } else {
        if (!this.editedItem.roleLinkId) {
          this.errorMsg.roleLinkId = true;
        }

        return false;
      }
    }, // .....end of isValidated()
    addVendor() {
      this.vendorname = this.vendorname.trim();
      const data = {
        vendorname: this.vendorname,
      };
      if (this.vendorname && this.vendorname != "") {
        axios
          .post("/admin/addVendor", data)
          .then((res) => {
            if (res.data.msg == "success") {
              this.searchVendorDropdown(this.vendorname)
              this.vendor_dialog = false;
              this.vendorname = "";
              this.showSnackbar("green", "Vendor Added Successfully.");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => { });
      } else {
        this.showSnackbar("#b71c1c", "Please enter required fields");
        this.$refs.form.validate();
      }
    },

    addBill() {
      this.billnumber = this.billnumber.trim();
      const data = {
        billnumber: this.billnumber,
        price: this.price,
        bookprice: this.bookprice,
        discount: this.discount,
        vendor: this.vendor,
        purchasedate: this.purchasedate,
        ponumber: this.ponumber,
        podate: this.podate,

      };
      if (this.billnumber && this.billnumber != "") {
        axios
          .post("/admin/addBill", data)
          .then((res) => {
            if (res.data.msg == "success") {
              this.billsearch('id', res.data.billid);
              this.bill_dialog = false;
              this.billnumber = "";
              this.price = 0;
              this.bookprice = 0;
              this.discount = 0;
              this.vendor = "";
              this.purchasedate = "";
              this.ponumber = "";
              this.podate = "";
              this.showSnackbar("green", "Bill Added Successfully.");
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong!!!");
            console.log(error);
          })
          .finally(() => { });
      } else {
        this.showSnackbar("#b71c1c", "Please enter required fields");
        this.$refs.form.validate();
      }
    },

    billsearch(type, value) {
      if (value != "") {
        var params = { text: value, type: type };
        axios
          .post("/IssueBook/getsearchbybill", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.billlist = res.data.billlist;
            }
          })
      }
    },

    searchVendorDropdown(value) {
      if (value != "") {
        var params = { text: value };
        axios
          .post("/IssueBook/getsearchbyVendor", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.vendorlist = res.data.vendorlist;
            }
          })
      }
    },

    calbill_discount() {
      if (this.discount == 0) {
        this.bookprice = this.price;
      } else {
        var discount = (this.price * this.discount) / 100;
        this.bookprice = (this.price - discount);
      }
    },


  },
};
</script>
<style scoped>
.data {
  padding-left: 20px;
}

.bookdata {
  border: 2px solid gray;
  background: #d3d3d330;
  margin: 1rem 0rem 1rem 0rem;
  border-radius: 5px;
}

.value {
  white-space: nowrap;
  font-weight: bold;
  color: black;
}

.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}

.fields {
  padding: 0px !important;
}

.isdonated {
  margin-top: 15px;
  padding-left: 15px !important;
}

.bookInfo {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .bookInfo {
    /* display: block; */
  }
}
</style>
